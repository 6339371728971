import React, { Component } from "react";
import { Link } from "gatsby";

export default class footer extends Component {
  render() {
    
    return (
      
      <div className="site-footer" id="footer">
       <footer class="footer-distributed">

<div class="footer-left">

              <Link to="/" >
              <img src='//images.ctfassets.net/zaez3emoldzr/7yHuAa09NVOcZ25POk7cbM/aca9d51f89aaf94ebe0af03484b0d2e6/SZlogo__1_-removebg-preview.png' alt="logo" />
              </Link>
               
             

  <p class="footer-links">
    <Link to="/#home" class="link-1">Home</Link>
    
    <Link to={`/#About`}>About</Link>
  
    <Link to={`/#Service`}>Services</Link>
  
    <Link to={`/#Blogs`}>Blogs</Link>
    
    <Link to={`/#Work`}>Why Choose us</Link>
    
                              
    <Link to={`/#Testimonials`}>Testimonials</Link>
    
    <Link to={`/#Contact`}>Contact</Link>
  </p>

  <p class="footer-company-name">© 2021 {this.props.siteName}. ALL RIGHTS RESERVED.</p>
</div>

<div class="footer-center">

  <div>
    <i class="fa fa-map-marker"></i>
    <p>Our Address:<span>SZ Accounting Services 2600 </span>- 4720 Kingsway Burnaby, Canada</p> 
   
    
  </div>

  <div>
    <i class="fa fa-phone"></i>
    <p>Phone no:</p><br></br>
    <p>604-753 7162</p>
  </div>

  <div>
    <i class="fa fa-envelope"></i>
    <p>Our email:<br></br><a href="mailto:info@szaccounting.ca">info@szaccounting.ca</a></p>
  </div>

</div>

<div class="footer-right">

  <p class="footer-company-about">
    <span>About the company</span>
    <p>Our Professional tax expertise help claiming the maximum credits on every tax return to maximize your refund and improve the profit of your business</p>
  </p>

  <div class="footer-icons">
  
  <ul className="social">
              <li>
                <a
                  className="fab fa-facebook-f"
                  href={this.props.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-twitter"
                  href={this.props.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-instagram"
                  href={this.props.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-linkedin-in"
                  href={this.props.linkdin}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              
            </ul>
            
             <div >
             <p>Office Location:
            <iframe className="footer-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d165.1079181194317!2d-123.0003734973611!3d49.22772948559568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867659187985a3%3A0x3dceda387af73a52!2s2600-4720%20Kingsway%2C%20Burnaby%2C%20BC%20V5H%204N2%2C%20Canada!5e0!3m2!1sen!2s!4v1592311212801!5m2!1sen!2s" ></iframe>
            </p>
            </div>
  </div>


</div>
      
      </footer>
      </div>
      
    
    );
  }
}
